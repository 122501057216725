import { TableEditColumn } from "@devexpress/dx-react-grid-material-ui";
import AddCircleIcon from "@mui/icons-material/AddCircleRounded";
import CancelIcon from "@mui/icons-material/CancelRounded";
import CheckCircleIcon from "@mui/icons-material/CheckCircleRounded";
import DeleteIcon from "@mui/icons-material/DeleteRounded";
import EditIcon from "@mui/icons-material/EditRounded";
import { IconButton } from "@mui/material";

export interface IDataGridCommandCellProps extends TableEditColumn.CommandProps {
    disabled?: boolean;
}

const IconComponents = {
    add: AddCircleIcon,
    edit: EditIcon,
    delete: DeleteIcon,
    commit: CheckCircleIcon,
    cancel: CancelIcon,
};

const DataGridCommandCell = (props: IDataGridCommandCellProps) => {
    const { text, id, disabled } = props;
    const Component = IconComponents[id];

    return (
        <IconButton
            size="small"
            // onExecute is a bound function and should not be destructured to keep the correct scoping of "this"
            onClick={() => props.onExecute()}
            title={text}
            disabled={disabled}
        >
            <Component fontSize="small" color={id === "commit" && !disabled ? "primary" : undefined} />
        </IconButton>
    );
};
export default DataGridCommandCell;
