import { PagingPanel } from "@devexpress/dx-react-grid-material-ui";
import { styled } from "@mui/material";

const CustomPagingPanelContainer = styled(PagingPanel.Container)(({ theme }) => ({
    "&.Pager-pager": {
        borderTop: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(0, 2),
        // Select
        "& .PageSizeSelector-pageSizeSelector": {
            ...theme.typography.body2,
            color: theme.palette.text.secondary,
            paddingRight: theme.spacing(1),
        },
        // Pagination
        "& .Pagination-pagination": {
            "& .Pagination-button, & .Pagination-rowsLabel": {
                ...theme.typography.body2,
                color: theme.palette.text.secondary,
                padding: theme.spacing(1, 2),
            },
            "& .Pagination-activeButton": {
                color: theme.palette.text.primary,
            },
            "& .Pagination-button": {
                border: "none",
                fontSize: "0.75rem",
            },
        },
    },
}));

export default CustomPagingPanelContainer;
