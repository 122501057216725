import SettingsIcon from "@mui/icons-material/SettingsRounded";
import { TFunction } from "i18next";
import React from "react";
import TableIconButton from "./TableIconButton";

interface ITableSettingsButtonProps {
    title?: string | null;
    translate?: TFunction;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const TableSettingsButton = ({ title, translate, ...other }: ITableSettingsButtonProps) => {
    let resolvedTitle = title;
    if (title == null && translate !== undefined) {
        resolvedTitle = translate("Edit");
    }
    return (
        <TableIconButton title={resolvedTitle ?? ""} {...other}>
            <SettingsIcon />
        </TableIconButton>
    );
};
export default TableSettingsButton;
