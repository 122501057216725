import { Table } from "@devexpress/dx-react-grid-material-ui";
import { styled } from "@mui/material";

const DataGridNoDataComponent = styled((props: Table.NoDataCellProps) => <Table.NoDataCell {...props} />)(({ theme }) => ({
    "&.TableNoDataCell-cell": {
        padding: theme.spacing(1, 2, 1, 3),
    },
    "& .TableNoDataCell-textContainer": {
        left: "unset", // disable horizontal centering
    },
    "& .TableNoDataCell-text": {
        transform: "unset", // disable horizontal centering
        ...theme.typography.body2,
        color: theme.palette.text.disabled,
    },
}));

export default DataGridNoDataComponent;
