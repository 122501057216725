import { ListItem, styled } from "@mui/material";

const SidebarNavItem = styled(ListItem)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    ...theme.typography.body2,
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
    fontWeight: theme.typography.fontWeightMedium,
    margin: theme.spacing(2, 0, 1, 0),
}));

export default SidebarNavItem;
