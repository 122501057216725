import { Typography } from "@mui/material";
import { TreeItem } from "@mui/x-tree-view";
import { TreeNodeDto } from "api-shared";
import { translateFromProperty } from "../lib/translate";
import { Language } from "../translations/main-translations";

interface TreeNodeItemProps {
    treeNode: TreeNodeDto;
    language: Language;
    disabledItems: number[];
}

const TreeNodeItem = ({ treeNode, language, disabledItems }: TreeNodeItemProps) => {
    const disabled = disabledItems.includes(treeNode.id);
    const label = <Typography py={0.5}>{translateFromProperty(treeNode, "name", language)}</Typography>;

    return (
        <TreeItem key={treeNode.id} nodeId={treeNode.id.toString()} label={label} disabled={disabled}>
            {Array.isArray(treeNode.children)
                ? treeNode.children.map((childNode) => (
                      <TreeNodeItem key={childNode.id} treeNode={childNode} language={language} disabledItems={disabledItems} />
                  ))
                : null}
        </TreeItem>
    );
};

export default TreeNodeItem;
