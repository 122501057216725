import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TreeView } from "@mui/x-tree-view";
import { TreeNodeDto } from "api-shared";
import { SyntheticEvent } from "react";
import { useAlphanumericStringSort } from "../hooks/useAlphanumericStringSort";
import { sortTreeNodes } from "../lib/sort";
import { Language } from "../translations/main-translations";
import TreeNodeItem from "./TreeNodeItem";

interface ITreeNodeTreeProps {
    selected: number | null;
    disabledItems?: number[];
    handleSelect: (event: SyntheticEvent, nodeId: number | null) => void;
    treeNodes: TreeNodeDto[];
    language: Language;
    className?: string;
}

const TreeNodes = ({ selected, disabledItems, language, handleSelect, treeNodes, className }: ITreeNodeTreeProps) => {
    const { compare } = useAlphanumericStringSort();

    const sortedTreeNodes = sortTreeNodes([...treeNodes], language, compare);

    const onNodeSelect = (event: SyntheticEvent, nodeIds: string | string[]) => {
        const id = Array.isArray(nodeIds) ? +nodeIds[0] : +nodeIds;
        if (!Number.isNaN(id)) {
            handleSelect(event, id);
        }
    };

    return (
        <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            selected={String(selected)}
            onNodeSelect={onNodeSelect}
            className={className}
        >
            {sortedTreeNodes.map((treeNode) => (
                <TreeNodeItem key={treeNode.id} treeNode={treeNode} language={language} disabledItems={disabledItems ?? []} />
            ))}
        </TreeView>
    );
};

export default TreeNodes;
